





























































import Vue from 'vue'

// import InterestsApplyToAdsetModal from '../components/interests/InterestsApplyToAdsetModal.vue'
import BasePagePreloader from '../components/BasePagePreloader.vue'
import { mapActions, mapState, mapMutations } from 'vuex'
export default Vue.extend({
  name: 'InterestsExploreSelected',
  data () {
    return {
      blockButtonSuggestion: false
    }
  },
  components: {
    // InterestsApplyToAdsetModal,
    BasePagePreloader
  },
  methods: {
    ...mapActions('interests', ['saveCollection', 'searchSuggestions']),
    ...mapMutations('interests', ['setStateField']),
    saveProject () {
      this.saveCollection(this.$route.params.id)
        .then((response) => {
          console.log(response)
          this.setStateField({ field: 'appliedLayeringChanges', value: true })
        })
        .catch((error) => {
          console.log(error)
        })
    },
    applyToAdset () {
      this.$modal.show('apply-to-adset')
    },
    showSuggestion () {
      this.blockButtonSuggestion = true
      this.searchSuggestions({ arr_word: this.words_suggestion, locale: this.locale })
        .then((response) => {
          this.$router.push({ name: 'search' })
          this.$store.dispatch('notifications/addNotification', { id: Date.now(), body: 'Suggestions found successfully', type: 'success' })
          this.blockButtonSuggestion = false
          console.log(response)
        })
    },
    clearAll () {
      this.setStateField({ field: 'interestsCollection', value: [] })
      this.setStateField({ field: 'appliedLayeringChanges', value: true })
    }
  },
  computed: {
    ...mapState('interests', [
      'selectedInterests',
      'interestsCollection',
      'appliedLayeringChanges',
      'loadingInterestsCollection'
    ]),
    words_suggestion () {
      const words = []
      this.$store.state.interests.interestsCollection.forEach((item) => {
        words.push(item.name)
      })
      return words
    }
  }
})
